.ripple
  position: relative
  overflow: hidden
  will-change: transform

  &:after
    content: ""
    display: block
    position: absolute
    width: 100%
    height: 100%
    top: 0
    left: 0
    pointer-events: none
    background-image: radial-gradient(circle, #000 10%, transparent 10.01%)
    background-repeat: no-repeat
    background-position: 50%
    transform: scale(10, 10)
    opacity: 0
    transition: transform .5s, opacity 1s

  &:active:after
    transform: scale(0, 0)
    opacity: .2
    transition: 0s

.sf-mdl-textfield
  box-sizing: border-box
  font-size: 16px
  position: relative
  display: inline-block
  margin: 0
  padding: 20px 0
  font-family: "Helvetica","Arial",sans-serif

  input
    font-size: 16px
    padding: 4px 0
    width: 100%
    border: none
    border-bottom: 1px solid rgba(0,0,0,.12)
    outline: none
    font-family: inherit

    &:focus
      border-bottom-color: rgb(96,125,139)
      border-bottom-width: 2px

    &:focus + label, &:valid + label
      opacity: 1

  textarea
    width: 100%
    border: none
    border-bottom: 1px solid rgba(0,0,0,.12)
    outline: none
    font-size: 16px
    padding: 4px 0
    font-family: inherit

    &:focus
      border-bottom-color: rgb(96,125,139)
      border-bottom-width: 2px

    &:focus + label, &:valid + label
      opacity: 1

  label
    opacity: 0
    transition: opacity 300ms
    position: absolute
    color: rgb(96,125,139)
    font-size: 14px
    top: 4px
    left: 0
    pointer-events: none
    overflow: hidden
    white-space: nowrap

.sf-mdl-date
  box-sizing: border-box
  position: relative
  padding: 20px 0

  input
    box-sizing: border-box
    border: none
    border-bottom: 1px solid rgba(0,0,0,.12)
    font-size: 14px
    margin: 0
    padding: 3px 0 1px 0

  input:focus
    outline: none
    border-bottom-color: rgb(96,125,139)
    border-bottom-width: 2px

  label
    position: absolute
    top: 4px
    left: 0
    pointer-events: none
    overflow: hidden
    white-space: nowrap
    color: rgb(96,125,139)
    font-size: 12px

.sf-mdl-select
  box-sizing: border-box
  position: relative
  padding: 20px 0

  select
    box-sizing: border-box
    border: none
    border-bottom: 1px solid rgba(0,0,0,.12)
    font-size: 14px
    margin: 0
    padding: 3px 0 4px 0

  select:focus
    outline: none
    border-bottom-color: rgb(96,125,139)
    border-bottom-width: 2px

  label
    position: absolute
    top: 4px
    left: 0
    pointer-events: none
    overflow: hidden
    white-space: nowrap
    color: rgb(96,125,139)
    font-size: 12px

.sf-mdl-button
  display: flex
  flex-direction: row
  align-items: center
  justify-content: space-around
  background-color: $white-2
  flex-shrink: 0
  box-shadow: $shadow-0
  border: none
  border-radius: 2px
  min-width: 64px
  text-transform: uppercase
  line-height: 36px
  cursor: pointer
  padding: 0 16px
  outline: none
  margin: 15px 0 16px 1.5rem
  font-size: 0.75rem
  font-weight: bold
  &.data-link
    background-color: $white-0
    &:hover
      background-color: $white-2

  svg
    height: 30px
    padding-right: 1rem
    pointer-events: none

    &.clickable
      pointer-events: initial

  &[disabled] svg
    fill: graytext
  
  &.icon-button svg
    padding: 0

.sf-mdl-checkbox
  line-height: initial
  text-align: center
