#article-list-title
  font-weight: bold
  padding-top: 67px


.article-headers
  display: flex
  padding-top: 14px
  flex-wrap: wrap

  span
    margin-left: 24px
    font-weight: normal
    color: $label-color
    white-space: nowrap

ul.article-list
  padding-top: 0
  margin-top: 0


  li
    padding: 0
    flex-wrap: wrap

    &>*
      margin-left: 1.5rem
      height: 67px

    .actions
      display: flex

      button
        height: 27px
        line-height: normal
        margin: 20px 0 20px 24px

        svg
          height: 27px
          padding: 0
        
          &.save-icon
            height: 20px

.article-header-item
  width: initial
  flex: 1 1 70px

  &:nth-child(4)
    flex: 3 1 160px

  &:nth-child(5)
    flex: 1 0 180px
