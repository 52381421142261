// Smartphone breakpoint

$phone-query: "screen and (max-width: 400px), screen and (max-height: 400px)"

// pass the query to the dom, where JS can get it
body::before
  display: none
  content: $phone-query

// material design box shadows

$shadow-0: 0 2px 2px 0 rgba(0,0,0,.14),0 3px 1px -2px rgba(0,0,0,.2),0 1px 5px 0 rgba(0,0,0,.12)
$shadow-1: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)
$shadow-2: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)
$shadow-3: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)
$shadow-4: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22)

// material design light color theme

$dark-1: #323232

$white-0: white
$white-1: #FAFAFA
$white-2: #F5F5F5
$white-3: #EEEEEE
$white-4: #E0E0E0
$white-5: #BDBDBD

$label-color: #607d8b

$grey: #607d8b

$primary-color: #607d8b
