@import "./constants_mixins.sass"
@import "./sf-mdl.sass"
@import "./elements/sideNav.sass"
@import "./elements/dialogs.sass"
@import "./elements/header.sass"
@import "./elements/spinner.sass"
@import "./views/invoicePrint.sass"
@import "./views/articlePage.sass"
@import "./views/carPage.sass"
@import "./views/orderPage.sass"
@import "./views/docsPage.sass"
@import "./views/login.sass"

body
  margin: 0
  background-color: #FAFAFA
  position: relative
  overflow-x: hidden
  font-family: "Helvetica","Arial",sans-serif
  min-height: 100vh

.page-content
  padding: 56px 1rem 1rem 1rem
  max-width: 900px
  margin: 0 auto
  box-shadow: $shadow-1
  background-color: white
  position: relative

.button-zeile
  margin-top: 2rem
  margin-right: 1rem
  justify-content: space-between
  flex-wrap: wrap
  @media #{$phone-query}
    display: none !important

svg
  min-width: 1rem
  min-height: 1rem

ul
  padding: 8px 0
  list-style: none

  li
    box-sizing: border-box
    display: flex
    align-items: center
    font-size: 16px
    letter-spacing: 0.65px
    flex-direction: row
    flex-wrap: nowrap
    padding: 16px
    cursor: default
    overflow: hidden

// for filterable lists (e.g. articles or documents)
.filter-row
  z-index: 2
  position: fixed
  box-sizing: border-box
  width: 100vw
  max-width: 900px
  padding-right: 3rem
  background-color: white
  display: flex
  flex-direction: row
  align-items: center

  >span
    flex-shrink: 0
    width: 6.75rem
    font-weight: bold

  .sf-mdl-textfield
    flex: 1 1 auto

.post-filter-row
    padding-top: 67px

// page-content
.page-content > .page-section
  display: flex
  flex-direction: row
  align-items: center
  margin-top: 16px
  border-bottom: 1px solid rgba(0, 0, 0, 0.12)
  @media #{$phone-query}
    flex-wrap: wrap

  .section-title
    width: 110px
    flex-shrink: 0
    font-weight: bold
    font-size: 14px
    @media #{$phone-query}
      width: 100%

  .section-content
    display: flex
    flex-direction: row
    flex-wrap: wrap
    flex-grow: 1
    overflow-x: hidden

  button.data-link, button.unique-button
    @media #{$phone-query}
      margin: 15px 10px
      flex-shrink: 1

// dateneingabe
.page-content.eingabe > .page-section
  .sf-mdl-select, .sf-mdl-textfield, .sf-mdl-date
    margin-left: 1.5rem
    flex: 1 1 130px
    max-width: 15rem
    height: 67px

  .sf-mdl-textfield.full-width
    max-width: none
    height: auto
    flex-grow: 1

// datenanzeige
.page-content > .page-section
  .page-section__category
    font-size: 16px
    margin: 23.5px 1px 23.5px 1.5rem

    @media #{$phone-query}
      margin: 10px 10px 10px 0

    >span
      border-bottom: 1px solid rgba(0,0,0,.12)

  .page-data
    display: inline-block

  &.large-only
    .view-toggle
      text-align: right
      font-size: 1.5rem
      font-weight: bold
      pointer-events: none
      float: right
      margin-bottom: 0.75rem
      display: none
      @media #{$phone-query}
        display: inline-block
        margin: 0

    .section-content
      display: flex

    @media #{$phone-query}
      overflow-y: hidden
      transition: height 500ms ease-in-out
      height: 36px

// settings-page
.page-content.settings > .page-section .section-content
  &>*
    margin-left: 1.5rem

  .settings-mail
    min-width: 300px
