// login -dialog
div#login-dialog, div#register-dialog, div#forgot-pass-dialog
  +dialog-backdrop()

  div.dialog-container
    +dialog-container()
    overflow-x: auto

    div.image-row
      position: relative
      display: flex
      flex-direction: row
      align-items: center
      min-height: 30px

      svg
        position: absolute
        height: 100%
        margin-right: 15px
        margin-bottom: 15px

      span
        flex: 1 1 auto
        font-weight: bold
        font-size: 1.5rem
        text-align: center

    div.content
      flex-grow: 1
      font-size: 1.25rem
      line-height: 200%
      text-align: center

      &.error
        font-weight: bold
        color: red

      &.success
        font-weight: bold
        color: green
  
    div.input form>div
      width: 100%

    div.actions
      flex-shrink: 0
      display: flex
      flex-wrap: wrap
      justify-content: space-around
      margin-top: 1rem
      padding-bottom: 15px
      border-bottom: 2px solid $grey

      button
        margin: 10px 10px 0 0

    div.further-actions
      display: flex
      flex-shrink: 0
      flex-direction: row
      justify-content: space-around
      flex-wrap: wrap

      button
        margin: 15px 10px 0 0
        line-height: 22px
        font-size: 0.6rem
        padding: 0 8px