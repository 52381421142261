.page-content.anzeige.ORDER > .page-section
  &.position
    .section-content 
      flex-wrap: nowrap

      @media #{$phone-query}
        flex-direction: column

      .position-group
        display: flex
        flex-direction: column
        justify-content: space-around
        flex: 0 0 auto
        margin-right: 10px

        .page-section__category
          margin: 10px 0

      .position-group:first-child
        flex: 1 1 auto
  
.page-content.eingabe.ORDER > .page-section
  .preisSumme
    font-size: 16px
    margin: 23.5px 1px 23.5px 1.5rem
    border-bottom: 1px solid rgba(0, 0, 0, 0.12)

  div.sum-grouping
    display: flex
    flex-direction: row

  &.invoice-position
    .section-title-container
      display: flex
      flex-direction: column

      @media #{$phone-query}
        flex-direction: row
        align-items: center
        width: 100%

      .section-title
        flex: 1 1 auto

      &>button
        padding: 0
        margin: 20px 0 0 0
        flex: 0 0 auto
        width: 64px
        height: 2rem

        @media #{$phone-query}
          margin: 0 10px

      button.icon-button>svg
        width: 24px
        padding: 0
        pointer-events: none  

    .section-content 
      .position-group
        display: flex
        flex-direction: column
        
        .article-title-group
          display: flex

          &>div:first-child
            flex: 1 1 auto

      .position-group:first-child
        flex: 1 1 auto

      &>button.removePosition.icon-button
        height: 36px

        &>svg
          width: 24px
          padding: 0
          pointer-events: none
    
    div.artikelNr
      flex: 0 1 auto
      min-width: 80px
      max-width: none

    div.textarea
      max-width: none
      flex: 1 1 auto

      textarea
        height: 100%
        box-sizing: border-box

    div.number
      flex: 0 1 auto
      min-width: 30px

div#order-filter-dialog
  +dialog-backdrop()

  div.dialog-container
    +dialog-container()
    width: 80vw

    div.search-results
      flex: 1 1 auto
      font-size: 1.25rem
      line-height: 200%
      text-align: center
      overflow-y: auto

      .order-filter-result
        border-bottom: 1px solid $primary-color
        span
          white-space: nowrap

    div.search
      display: flex
      flex-direction: row
      margin-bottom: 8px
      flex: 0 0 auto

      input
        border: none
        border-bottom: 1px solid rgba(0,0,0,.12)
        display: block
        font-size: 16px
        margin: 0
        padding: 4px 0
        width: 100%
        background: 0 0
        text-align: left
        color: inherit
        height: 26px

        &:focus
          outline: none
          border-bottom: 1px solid rgb(96,125,139)
      
      button.icon-button
        margin-top: 0
        margin-bottom: 0

        svg
          height: 25px
          padding-right: 0

    div#top-filter-rules-row
      display: flex
      flex-direction: row
      cursor: pointer
      flex: 0 0 auto

      span
        flex: 1 1 auto
        pointer-events: none

      svg
        height: 24px
        pointer-events: none
   
