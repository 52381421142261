// dialog / modal mixin
=dialog-backdrop()
  position: fixed
  top: 0
  bottom: 0
  z-index: 5
  width: 100vw
  height: 100vh
  display: flex
  justify-content: space-around
  align-items: center
  background-color: rgba(0,0,0,0.4)

=dialog-container()
  min-height: 10rem
  max-width: 80vw
  max-height: 80vh
  min-width: 250px
  display: flex
  flex-direction: column
  padding: 12px
  background-color: white
  box-shadow: $shadow-4

// auswahlListe
div#selection-dialog
  +dialog-backdrop()

  div.list-container
    +dialog-container()

    width: auto
    text-align: center

  input
    border: none
    border-bottom: 1px solid rgba(0,0,0,.12)
    display: block
    font-size: 16px
    margin: 0
    padding: 4px 0
    width: 100%
    background: 0 0
    text-align: left
    color: inherit
    min-height: 26px

  input:focus
    outline: none
    border-bottom: 1px solid rgb(96,125,139)

  ul
    overflow: auto

  li:hover
    box-shadow: $shadow-1
    background-color: $white-1

  svg
    margin-right: 1rem

// confirmationDialog
div#confirm-dialog
  +dialog-backdrop()

  div.dialog-container
    +dialog-container()

    div.content
      flex: 1 1 auto
      font-size: 1.25rem
      line-height: 200%
      text-align: center
      overflow-y: auto

    div.actions
      flex: 0 0 auto
      display: flex
      justify-content: space-around
      margin-top: 1rem

// confirmationDialog
div#input-dialog
  +dialog-backdrop()

  div.dialog-container
    +dialog-container()

    div.content
      flex-grow: 1
      font-size: 1.25rem
      line-height: 200%
      text-align: center

    div.input
      text-align: center
      div.sf-mdl-textfield
        width: 80%

    div.actions
      display: flex
      flex-direction: row
      justify-content: space-around
      margin-top: 1rem

div#email-dialog
  +dialog-backdrop()
  @media #{$phone-query}
    width: 100%
    height: 100%

  div.dialog-container
    +dialog-container()
    width: 800px
    height: 800px
    @media #{$phone-query}
      max-width: 80%
      max-height: 80%

    div.header
      font-size: 1.25rem
      line-height: 200%
      text-align: center

    div.meta
      display: flex
      justify-content: space-between

      div.sf-mdl-textfield
        flex-grow: 1

      div:first-child
        margin-right: 15px

    div.message-section
      flex-grow: 1
      display: flex
      flex-direction: column

      span
        font-weight: bold

      // text area container
      div
        flex: 1 1 auto
        display: flex

        textarea
          flex: 1 1 auto

    div.sf-mdl-textfield label
      opacity: 1

    div.actions
      display: flex
      flex-direction: row
      justify-content: space-around
      margin-top: 1rem
