#docs-list-title
  font-weight: bold
  padding-top: 67px

.doc-width
  overflow: hidden
  text-overflow: ellipsis

  &.sf-mdl-checkbox
    flex: 0 0 110px
  
    input
      top: 4px

  &.nummer
    flex: 0 0 115px

  &.art
    flex: 0 0 155px

  &.titel
    flex: 1 1 auto

.docs-headers
  display: flex
  padding-top: 14px
  flex-wrap: wrap
  margin-left: 24px

  span
    line-height: 40px
    width: initial
    font-weight: normal
    color: $label-color

ul#docs-list
  padding-top: 0
  margin-left: 24px
  margin-top: 0

  li
    padding: 0
    line-height: 40px
    flex-wrap: wrap
    border-bottom: 1px solid $grey

    @media #{$phone-query}
      justify-content: space-between
      
      .sf-mdl-checkbox, .doc-width.nummer, .doc-width.art
        flex: 0 0 auto

    .sf-mdl-checkbox
      flex: 0 0 75px

    .button-box
      display: flex
      flex-direction: row

      button
        height: 27px
        line-height: normal
        margin: 5px 0 5px 24px

        svg
          height: 27px
          padding: 0
