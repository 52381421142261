@media print
  .no-print, .no-print *, #toast
    display: none !important

  html, body, div.page-content.invoice
    width: 100%

  body
    background-color: white

  aside, header.mdl-layout__header
    display: none

@media screen
  .no-screen, .no-screen *
    display: none !important

  div.page-content.invoice
    border: 1px solid $grey
    width: 210mm // A4 page width

div.page-content.invoice
  font-size: 14px
  font-family: serif
  box-sizing: border-box
  line-height: initial
  margin: 0 auto
  padding: 15mm
  min-height: 292mm // actually 297mm is A4; this is to get a space for printing
  display: flex
  flex-direction: column
  box-shadow: none

  &.print
    font-size: 12px

  div.name-header
    font-size: 1.4em
    text-align: center
    margin-bottom: 25px

  div.name-header>div:first-child
    font-size: 2.5em

  div.invoice-line
    font-size: 0.7em
    margin-bottom: 5px
    width: 100%
    border-top: 1px solid black
    
    .left
      display: inline-block

    .right
      width: 30mm
      float: right
      text-align: right

  #document-type
    font-weight: bold
    font-size: 2em
    display: block
    text-align: center
    margin-bottom: 12mm

  table.invoice-information
    margin-bottom: 8mm

    td
      width: 40mm

      &:first-child
        wiht: 27mm

      span
        display: block
        min-height: 15px

    td:nth-child(3)
      padding-left: 35px

    .information-title
      font-weight: bold

      &:first-child
        padding-left: 5mm

    td.information-content
      border-bottom: 1px solid black

  table.content
    width: 100%
    margin-bottom: auto
    border-collapse: collapse

    th
      font-weight: normal
      border-bottom: 1px solid black

    th:nth-child(1)
      width: 10mm
    th:nth-child(2)
      width: 25mm
    th:nth-child(3)
      width: 67mm
    th:nth-child(4)
      width: 23mm
    th:nth-child(5)
      width: 25mm
    th:nth-child(6)
      width: 25mm
    th:nth-child(7)
      width: 25mm

    td
      border: 1px solid #bdbdbd
      text-align: center

  table.sum
    width: 100%
    border-collapse: collapse
    margin: 15px 0

    td, th
      width: 33%
      height: 5mm
      font-weight: bold
      border: 1px solid black
      text-align: center

  div.general-remark
    text-align: center
    margin-bottom: 15px

  div.foot-note
    text-align: center
    line-height: 20px

    div.bank
      text-decoration: underline

