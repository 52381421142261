.side-nav-container
  pointer-events: none
  position: fixed
  top: 0
  left: 0
  width: 100%
  height: 100%
  z-index: 5

  &::before
    content: ""
    display: block
    position: fixed
    top: 0
    left: 0
    width: 100%
    height: 100%
    background-color: rgba(0,0,0,0.4)
    opacity: 0
    will-change: opacity
    pointer-events: none
    transition: opacity 0.5s ease-in-out

  &.visible
    pointer-events: auto

    &::before
      opacity: 1

    .side-nav
      transform: translate(0)

.side-nav
  box-sizing: border-box
  position: fixed
  top: 0
  padding: 1rem
  width: 75%
  max-width: 250px
  height: 100%
  box-shadow: 0 2px 2px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.2), 0 1px 5px 0 rgba(0,0,0,.12)
  border-right: 1px solid #e0e0e0
  background: #FAFAFA
  will-change: translate
  left: 0
  transform: translate(-102%)
  transition: transform 0.5s ease-in-out

  display: flex
  flex-direction: column
  flex-wrap: nowrap

  button
    display: block

  #side-nav-title
    font-size: 1.5rem
    margin-bottom: 1rem
    cursor: pointer
    display: flex
    flex-direction: row
    align-items: center

    svg
      width: 36px
      margin-right: 16px

  #side-nav-content
    flex: 1 1 auto
    display: flex
    flex-direction: column
    overflow-y: auto

    #perma-button-div
      flex: 1 0 auto
      display: flex
      flex-direction: column-reverse

      button
        background-color: $white-4

    button
      margin: 1rem 0
      width: 100%
