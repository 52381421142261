.sf-mdl-textfield.reifen
  display: flex
  flex-direction: row
  align-items: baseline

  label 
    opacity: 1

  &>span
    padding-right: 3px
    flex: 0 0 auto

  &>input
    width: auto
    flex: 0 0 auto

.page-content.eingabe > .page-section .sf-mdl-textfield.sf-mdl-textfield.vin
  flex: 3 1 200px

.page-content.eingabe > .page-section .sf-mdl-textfield.sf-mdl-textfield.zu
  flex: 1 1 75px