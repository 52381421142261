header.sf-mdl-header
  position: fixed
  top: 0
  box-sizing: border-box
  display: flex
  height: 56px
  flex-direction: row
  align-items: center
  width: 100%
  margin: 0
  padding: 0 30px
  border: none
  z-index: 3
  background-color: $primary-color
  color: white
  box-shadow: $shadow-0

  .open-nav
    flex-shrink: 0
    fill: white
    margin-right: 1.5rem
    cursor: pointer

  .sf-mdl-header-title
    flex-grow: 1
    flex-shrink: 1
    text-overflow: ellipsis
    white-space: nowrap
    overflow: hidden
    font-size: 20px
    user-select: none

    @media #{$phone-query}
      display: none

  nav
    display: flex
    flex-direction: row
    white-space: nowrap

    a
      font-size: 14px
      padding: 0 16px
      color: white
      cursor: pointer
      user-select: none

    #mobile-nav-span
      display: none

  @media #{$phone-query}
    nav
      flex-direction: column
      margin-left: auto
      align-self: baseline
      background-color: $grey
      box-shadow: $shadow-2
      transition: height 500ms ease-in-out
      height: auto
      overflow-y: hidden

      &.collapsed
        height: 56px
        box-shadow: none

        // a
        //   display: none

        #mobile-nav-span::before
          pointer-events: none
          visibility: hidden

      #mobile-nav-span
        font-size: 16px
        user-select: none
        cursor: pointer
        margin-left: 10px
        display: flex
        flex-direction: row
        align-items: center
        white-space: nowrap
        height: 56px
        user-select: none
        overflow-y: auto
        flex-shrink: 0
        -webkit-tap-highlight-color: rgba(255, 255, 255, 0)

        &::before
          content: ""
          display: block
          position: fixed
          top: 0
          left: 0
          width: 100%
          height: 100%
          pointer-events: auto
          visibility: initial

        &>span:first-child
          margin-right: 5px

      a
        z-index: 2
        font-size: 16px
        padding-bottom: 20px
